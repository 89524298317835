.nework-selector.network-ui {
  padding: 3px 8px 3px 0;

  .ant-select-selector {
    border-color: @input-border !important;
    box-shadow: none !important;
  }

  gap: 10px;
  width: 150px;
  background: @almost-white;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: @dark-color;

  .ant-select-selector {
    background: @almost-white;
    border: none;
  }

  .ant-select-arrow {
    color: @dark-color;
  }
}

.network-switch-alert {
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  margin: 16px;

  .path-contract & {
    top: 73px;
  }

  .ant-alert-message {
    font-weight: bold;
  }

  .ant-alert-error {
    border-radius: 12px;
    box-shadow: 0 0 12px 0 #ffccc7;
    border-width: 3px;
  }

  .network-switch-button {
    display: block;
    margin-top: 6px;

  }
}
