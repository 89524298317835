@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Ttw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTtw.ttf) format('truetype');
}
body {
  background: #efeaff;
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
*::-webkit-scrollbar {
  display: none;
}
button.primary {
  background-color: #551d98;
  padding: 14px 16px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 50px;
  max-width: 180px;
  text-align: center;
  color: #ffffff;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
button.primary:focus,
button.primary:hover {
  color: #551d98;
  background-color: #ffffff;
  border: 2px solid #551d98;
  transition: all 0.3s ease-out;
}
.standard-input input {
  background-color: transparent;
}
.standard-input .ant-input-affix-wrapper {
  background-color: transparent;
  animation: none;
  border-radius: 8px;
  border: none;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.standard-input {
  background-color: transparent;
  animation: none;
  border-radius: 8px;
  border: none;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.standard-input {
  background-color: rgba(239, 241, 249, 0.6);
  margin-bottom: 16px;
}
.standard-input::placeholder,
.standard-input input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #abafb1;
}
.ant-input-group-addon {
  background-color: rgba(239, 241, 249, 0.6);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
}
.account-action-button {
  color: rgba(255, 0, 0, 0.6);
  font-style: normal;
  font-weight: 400;
  border: none;
  font-size: 14px;
  line-height: 22px;
  width: 100px;
  height: 100%;
  margin-left: 0;
}
.account-action-button.connect {
  color: #ae92ff;
}
.account-action-button:hover {
  color: #ff0000;
  font-size: 15px;
}
.account-action-button:hover.connect {
  color: #551d98;
}
.index-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .index-container {
    background-color: transparent;
  }
}
.index-container .search-container {
  border-radius: 12px;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .index-container .search-container {
    max-width: 470px;
    width: 90%;
    box-shadow: 0 0 15px -5px #551d98;
  }
}
.index-container .search-container .search-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 365px;
}
@media (min-width: 768px) {
  .index-container .search-container .search-content {
    margin-top: 60px;
  }
}
.index-container .search-container .search-content img {
  width: 120px;
}
.index-container .search-container .search-content h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0;
  text-align: center;
  margin: 5px 0 0;
}
.index-container .search-container .search-content h3 {
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
}
.index-container .search-container .search-content .search-tabs {
  margin-top: 10px;
  max-width: 360px;
  margin-left: 10px;
  margin-right: 10px;
}
.index-container .search-container .search-content .search-tabs .ant-tabs-tab:hover {
  color: #551d98;
}
.index-container .search-container .search-content .search-tabs .ant-tabs-tab + .ant-tabs-tab {
  text-align: center;
  margin: 0 10px;
}
@media (min-width: 400px) {
  .index-container .search-container .search-content .search-tabs .ant-tabs-tab + .ant-tabs-tab {
    width: 150px;
  }
}
.index-container .search-container .search-content .search-tabs .ant-tabs-nav-wrap {
  font-size: 14px;
  line-height: 22px;
  color: #212942;
}
.index-container .search-container .search-content .search-tabs .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #212942;
}
.index-container .search-container .search-content .search-tabs .ant-tabs-nav-wrap .ant-tabs-tab-btn {
  margin: 0 auto;
}
.index-container .search-container .search-content .search-tabs .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  background: #551d98;
}
.index-container .search-container .search-content .search-tabs .ant-tabs-content-holder {
  height: 120px;
}
.index-container .search-container .search-content .search-tabs .ant-tabs-content-holder .ant-tabs-tabpane {
  display: flex;
  flex-direction: column;
  height: 140px;
  opacity: 0;
  transition: all 1s;
}
.index-container .search-container .search-content .search-tabs .ant-tabs-content-holder .ant-tabs-tabpane-active {
  opacity: 1;
  transition: all 1s;
}
.index-container .search-container .search-content .quick-access-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.index-container .search-container .search-content .quick-access-container h5 {
  text-align: center;
}
.index-container .search-container .search-content .quick-access-container .contract-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.index-container .search-container .search-content .quick-access-container .contract-link-container .contract-link {
  width: 33%;
  text-align: center;
  color: #551d98;
}
.index-container .search-container .footer-items {
  margin-top: 60px;
}
.side-contract-menu {
  background-color: #ffffff;
  width: 280px;
  min-width: 180px;
  height: calc(100vh - 60px);
  padding: 35px 10px 0 20px;
}
.side-contract-menu .side-contract-name h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.side-contract-menu .side-contract-name h4 {
  font-weight: 100;
  font-size: 14px;
  line-height: 17px;
}
.address-display a {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #5e6475;
}
.no-methods-placeholder {
  color: #5e6475;
  display: flex;
  justify-content: center;
  font-size: 16px;
  border: 2px dashed #ae92ff;
  margin: 20px 20px 0;
  padding: 25px 20px;
  opacity: 0.5;
  border-radius: 12px;
}
@media (min-width: 768px) {
  .no-methods-placeholder {
    margin: 60px 30px 0;
    padding: 150px 20px;
    font-size: 20px;
  }
}
.no-methods-placeholder .mobile {
  display: inherit;
  margin-left: 4px;
}
.no-methods-placeholder .desktop {
  display: none;
}
@media (min-width: 768px) {
  .contract-column {
    width: calc(100vw - 280px);
  }
}
.contract-column.contract-main {
  position: relative;
  order: 1;
}
.contract-column.contract-main .contract-info {
  background-color: #ffffff;
  border-radius: 8px;
  z-index: 100;
  right: 20px;
  top: 10px;
  width: 200px;
  cursor: pointer;
}
.contract-column.contract-main .contract-info .ant-collapse-item {
  border-bottom: none;
}
@media (min-width: 768px) {
  .contract-column.contract-main .contract-info {
    position: absolute;
    width: 300px;
  }
}
.contract-column.contract-main .contract-info svg {
  fill: #551d98;
}
.contract-column.contract-main .contract-info .anticon-info-circle svg {
  fill: #5e6475;
}
.contract-column.contract-main .contract-info .ant-collapse-content-box {
  padding-left: 10px;
}
.contract-column.contract-main .contract-info .ant-collapse-content-box .address-row {
  margin-top: 10px;
  margin-bottom: 10px;
}
.contract-column.contract-main .contract-info .ant-collapse-header {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #212942;
  padding: 10px 0 10px 0;
  text-align: center;
}
@media (min-width: 768px) {
  .contract-column.contract-main .contract-info .ant-collapse-header {
    padding: 10px;
  }
}
.contract-column.contract-main .contract-info .contract-variable-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #212942;
}
.contract-column.contract-main .contract-info .address-balance {
  margin-left: 10px;
}
.contract-column.contract-main .contract-info .contract-variable-value {
  font-weight: 100;
  font-size: 12px;
  line-height: 20px;
  color: #212942;
}
.contract-column h3 {
  margin-top: 20px;
  margin-left: 20px;
}
.contract-column .function-container {
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contract-column .function-container .contract-method {
  width: 100%;
  transition: all 0.3s;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 25px 16px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 0 9px -5px #551d98;
}
.contract-column .function-container .contract-method button.primary {
  margin: 0 auto;
  height: 32px;
}
@media (min-width: 768px) {
  .contract-column .function-container .contract-method button.primary {
    position: absolute;
    right: 16px;
    top: 22px;
    margin: 0;
  }
}
.contract-column .function-container .contract-method .contract-method-input label {
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  color: #5e6475;
  margin-bottom: 10px;
}
.contract-column .function-container .contract-method .contract-method-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #212942;
}
.contract-column .function-container .contract-method .contract-method-name .anticon {
  margin-right: 10px;
  line-height: 10px;
}
.contract-column .function-container .contract-method .contract-result-output {
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  color: #212942;
  border: none;
  position: relative;
}
.contract-column .function-container .contract-method .contract-result-output .result-icon {
  color: green;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
}
.contract-column .function-container .contract-method .contract-result-output .result-text {
  position: absolute;
  top: 0;
  right: 0;
}
.contract-column .function-container .contract-method .contract-result-output .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
}
.contract-column .function-container .contract-method .contract-result-output span {
  font-weight: 100;
}
@media (max-width: 1000px) {
  .contract-column .function-container .contract-method {
    width: 100%;
  }
}
@media (max-width: 720px) {
  .contract-column .function-container {
    display: block;
  }
}
.contract-column .contract-header {
  display: flex;
  justify-content: space-between;
}
.contract-column .contract-header .primary {
  height: 25px;
  margin-bottom: 20px;
  color: #ffffff;
  background-color: #551d98;
  border: 2px solid #551d98;
  transition: all 0.3s ease-out;
  align-self: center;
  width: 150px;
  margin-right: 14px;
}
.contract-column .contract-header .primary:hover {
  color: #551d98;
  background-color: #ffffff;
  border: 2px solid #551d98;
  transition: all 0.3s ease-out;
}
.contract-container-main {
  overflow-y: auto;
  height: calc(100vh - 70px);
  padding-top: 15px;
}
.contract-container-main ::-webkit-scrollbar {
  display: none;
}
.contract-navigation-content {
  background-color: #ffffff;
  padding: 0;
  max-width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.contract-navigation-content ::-webkit-scrollbar {
  display: inherit;
}
.contract-navigation-content ::-webkit-scrollbar {
  width: 5px;
}
.contract-navigation-content ::-webkit-scrollbar-track {
  background: #e6e6e6;
}
.contract-navigation-content ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}
.contract-navigation-content ::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 50px;
}
.contract-navigation-content .ant-collapse-ghost {
  margin-top: 10px;
  overflow-y: auto;
  padding-left: 15px;
}
.contract-navigation-content .ant-collapse-ghost .ant-collapse-content-box {
  padding: 0;
}
.contract-navigation-content .ant-collapse-ghost .ant-collapse-content-box li {
  padding-left: 0;
}
.contract-navigation-content .contract-navigation-title {
  white-space: nowrap;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 15px;
  min-height: 90px;
}
.contract-navigation-content .contract-navigation-title * {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contract-navigation-content .contract-navigation-title h2 {
  margin: 0;
}
.contract-navigation-content .contract-navigation-title h4 span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.contract-navigation-content .contract-navigation-title svg {
  fill: #551d98;
}
.contract-navigation-content .ant-collapse-header {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}
.contract-navigation-content .ant-collapse-ghost {
  flex-grow: 1;
}
.contract-navigation-content .primary {
  height: 25px;
  margin-bottom: 20px;
  color: #551d98;
  background-color: #ffffff;
  border: 2px solid #551d98;
  transition: all 0.3s ease-out;
  align-self: center;
  width: 150px;
  margin-right: 14px;
}
.contract-navigation-content .primary:hover {
  color: #551d98;
  background-color: #ffffff;
  border: 2px solid #551d98;
  transition: all 0.3s ease-out;
}
.contract-navigation-content ul {
  padding: 0;
}
.contract-navigation-content ul li {
  width: 100%;
  height: 30px;
  list-style: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.contract-navigation-content ul li.active {
  display: flex;
  justify-content: space-between;
  background-color: #f9f7ff;
  border-radius: 4px;
}
.contract-navigation-content ul li.active > span:nth-child(1) {
  width: 100%;
}
.contract-navigation-content ul li.active > span:nth-child(2) svg {
  fill: #5e6475;
}
.contract-navigation-content ul li.header {
  font-weight: bold;
  padding: 0;
  margin-top: 10px;
}
.contract-loading-spinner {
  margin: 60px auto 0;
}
.menu-button {
  margin-left: 10px;
  z-index: 100;
  font-size: 20px;
  position: absolute;
  left: 5px;
  top: 13px;
}
.info-button {
  margin-left: 10px;
  z-index: 100;
  font-size: 20px;
  position: absolute;
  right: 11px;
  top: 13px;
}
@media (min-width: 768px) {
  .info-button {
    top: 30px;
    right: 15px;
    background-color: #ffffff;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.info-button-close {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 999;
  font-size: 20px;
}
@media (min-width: 768px) {
  .info-button-close {
    top: 45px;
    right: 25px;
  }
}
.menu-button-close {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 999;
  font-size: 20px;
}
.info-navigation {
  order: 1;
  height: auto;
  transition: all 0.3s;
}
@media (min-width: 991px) {
  .info-navigation {
    order: 2;
  }
}
.info-navigation .contract-navigation-title {
  padding-top: 2px;
}
.info-navigation .address-row {
  padding: 18px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-right: 20px;
  margin-left: 20px;
  box-shadow: 0 0 9px -5px #551d98;
}
@media (min-width: 991px) {
  .info-navigation .address-row {
    margin-right: 0;
    margin-left: 0;
  }
}
.contract-info-title {
  margin-left: 20px;
  margin-top: 20px;
}
@media (min-width: 992px) {
  .contract-info-title {
    margin-left: 0;
  }
}
.contract-navigation {
  transition: all 0.3s;
  min-width: 100%;
}
@media (min-width: 768px) {
  .contract-navigation {
    min-width: 0;
    margin-top: 0;
  }
}
.contract-navigation.open {
  display: block;
  position: absolute;
  z-index: 700;
  background-color: #ffffff;
  transition: all 0.3s;
}
@media (min-width: 768px) {
  .contract-navigation.open {
    min-width: 280px;
    padding-top: 0;
  }
  .contract-navigation.open .contract-navigation-content {
    width: 100%;
    margin: 0;
  }
}
.contract-navigation.open .contract-navigation-title {
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .menu-button-close,
  .menu-button {
    display: none;
  }
  .no-methods-placeholder .mobile {
    display: none;
  }
  .no-methods-placeholder .desktop {
    display: inherit;
    margin-left: 4px;
  }
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  height: 70px;
  align-items: center;
  padding: 0 10px 0 10px;
}
@media (min-width: 768px) {
  .header {
    padding: 0 10px 0 20px;
    justify-content: space-between;
  }
}
.header .address-balance {
  font-size: 14px !important;
}
.header .nework-selector {
  margin-left: 10px;
}
.header .menu-button {
  margin-left: 10px;
  z-index: 100;
  font-size: 20px;
  position: inherit;
  width: 100px;
  text-align: left;
  height: 60px;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .header .menu-button {
    display: none;
  }
}
.header .address-component a {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #5e6475;
}
.header svg {
  fill: #551d98;
}
.header .identicon {
  margin-top: 6px;
}
.header .header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
@media (min-width: 768px) {
  .header .header-logo {
    margin-left: 0;
    justify-content: flex-start;
  }
}
.header .header-logo .logo-link {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.header .header-logo .logo-link .logo-abi {
  font-weight: bold;
  font-size: 20px;
  margin-left: 3px;
}
.header .header-logo .logo-link .logo-abi .logo-ninja {
  font-size: 20px;
  font-weight: 100;
}
.footer-items {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 30px auto 10px;
  color: #212942;
}
.footer-items a {
  text-decoration: none;
  color: #551d98;
}
.footer-items .separator {
  padding-left: 4px;
  padding-right: 4px;
}
.nework-selector.network-ui {
  padding: 3px 8px 3px 0;
  gap: 10px;
  width: 150px;
  background: #f9f7ff;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212942;
}
.nework-selector.network-ui .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.nework-selector.network-ui .ant-select-selector {
  background: #f9f7ff;
  border: none;
}
.nework-selector.network-ui .ant-select-arrow {
  color: #212942;
}
.network-switch-alert {
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  margin: 16px;
}
.path-contract .network-switch-alert {
  top: 73px;
}
.network-switch-alert .ant-alert-message {
  font-weight: bold;
}
.network-switch-alert .ant-alert-error {
  border-radius: 12px;
  box-shadow: 0 0 12px 0 #ffccc7;
  border-width: 3px;
}
.network-switch-alert .network-switch-button {
  display: block;
  margin-top: 6px;
}
